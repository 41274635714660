import { Center, Link, Skeleton, Text } from '@chakra-ui/react';
import React from 'react';

import type { IconName } from 'ui/shared/IconSvg';
import IconSvg from 'ui/shared/IconSvg';

type Props = {
  icon?: IconName;
  iconSize?: string;
  title: string;
  text: string;
  url: string;
  isLoading?: boolean;
  type: string;
}

const FooterLinkItem = ({ icon, iconSize, text, url, isLoading, type, title }: Props) => {
  if (isLoading) {
    return <Skeleton my="3px">{ text }</Skeleton>;
  }

  return (
    <Link
      href={ url }
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      variant="secondary"
      target="_blank"
      fontSize="xs"
      pb={{ lg: 6, base: 4 }}
      borderBottom="0.5px solid white"
    >
      { icon && (
        <Center gap={{ lg: 3, base: 2 }} minW={{ lg: '150px', base: '120px' }} justifyContent="flex-start">
          <IconSvg boxSize={ iconSize || 5 } name={ icon } color="white"/>
          <Text
            fontSize={{ lg: '20px', base: '14px' }}
            lineHeight={{ lg: '25px', base: '18px' }}
            fontWeight={ 400 }
            letterSpacing={{ lg: '0.2px', base: '0.14px' }}
            color="#FFFFFF"
          >{ title }</Text>
        </Center>
      ) }
      { type === 'email' ? (
        <IconSvg name="@" w={ 4 } h={ 4 }/>
      ) : (
        <IconSvg name="link_arrow" w={ 5 } h={ 5 }/>
      ) }
      <Text
        fontSize={{ lg: '20px', base: '14px' }}
        lineHeight={{ lg: '25px', base: '18px' }}
        fontWeight={ 250 }
        letterSpacing={{ lg: '0.2px', base: '0.14px' }}
        color="#FFFFFF"
        opacity={ 0.7 }
        textAlign="right"
        minW={{ lg: '180px', base: '140px' }}
      >{ text }</Text>
    </Link>
  );
};

export default FooterLinkItem;
