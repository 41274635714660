// import type { GridProps } from '@chakra-ui/react';
import {
  Box,
  // Grid,
  Flex,
  Text,
  Link,
  // Skeleton,
  // Icon,
} from '@chakra-ui/react';
// import { useQuery } from '@tanstack/react-query';
import React from 'react';

// import type { CustomLinksGroup } from 'types/footerLinks';

import config from 'configs/app';
// import logoIcon from 'icons/logo.svg';
// import type { ResourceError } from 'lib/api/resources';
// import useFetch from 'lib/hooks/useFetch';
// import useIssueUrl from 'lib/hooks/useIssueUrl';
// import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';

import LogoIcon from '../../logo';
import FooterLinkItem from './FooterLinkItem';
import IntTxsIndexingStatus from './IntTxsIndexingStatus';

// const MAX_LINKS_COLUMNS = 4;

const Footer = () => {

  const BLOCKSCOUT_LINKS = [
    {
      icon: 'email' as const,
      iconSize: '32px',
      title: 'Email',
      text: 'info@ultron.com',
      url: 'mailto:info@ultron.com',
      type: 'email',
    },
    {
      icon: 'social-twitter' as const,
      iconSize: '32px',
      title: 'X (ex-Twitter)',
      text: '@ultronfoundation',
      url: 'https://x.com/ultron_found',
      type: 'link',
    },
    {
      icon: 'social-instagram' as const,
      iconSize: '32px',
      title: 'Instagram',
      text: '@ultronfoundation',
      url: 'https://instagram.com/ultron.foundation?igshid=YmMyMTA2M2Y=',
      type: 'link',
    },
    {
      icon: 'social-telegram' as const,
      iconSize: '32px',
      title: 'Telegram',
      text: '@ultronfoundation',
      url: 'https://t.me/ultron_foundation',
      type: 'link',
    },
    {
      icon: 'social-cmc' as const,
      iconSize: '32px',
      title: 'CMC',
      text: '@ultronfoundation',
      url: 'https://coinmarketcap.com/currencies/ultron/',
      type: 'link',
    },
    {
      icon: 'social-linkedin' as const,
      iconSize: '32px',
      title: 'Linkedin',
      text: '@ultronfoundation',
      url: 'https://www.linkedin.com/company/ultron-foundation/',
      type: 'link',
    },
  ];

  // const fetch = useFetch();

  // const { isPlaceholderData, data: linksData } = useQuery<unknown, ResourceError<unknown>, Array<CustomLinksGroup>>({
  //   queryKey: [ 'footer-links' ],
  //   queryFn: async() => fetch(config.UI.footer.links || '', undefined, { resource: 'footer-links' }),
  //   enabled: Boolean(config.UI.footer.links),
  //   staleTime: Infinity,
  //   placeholderData: [],
  // });
  //
  // const colNum = isPlaceholderData ? 1 : Math.min(linksData?.length || Infinity, MAX_LINKS_COLUMNS) + 1;

  return (
    <Flex
      direction={{ base: 'column', lg: 'row' }}
      px={{ base: 4, lg: '95px' }}
      py={{ base: 4, lg: '100px' }}
      borderTop="1px solid"
      borderColor="divider"
      as="footer"
      columnGap={{ lg: '32px', xl: '100px' }}
    >
      <Box flexGrow="1" mb={{ base: 8, lg: 0 }} minW="195px">
        <Flex flexWrap="wrap" columnGap={ 8 } rowGap={ 6 }>
          { /*<ColorModeToggler/>*/ }
          { !config.UI.indexingAlert.intTxs.isHidden && <IntTxsIndexingStatus/> }
          { /*<NetworkAddToWallet/>*/ }
        </Flex>
        <Link href="/">
          <Box
            width={{ lg: '185px', base: 'fit-content' }}
            height={{ lg: '64px', base: '40px' }}
            display={{ lg: 'none', base: 'flex' }}
          >
            <LogoIcon width="100%" height="100%"/>
          </Box>
          <Box
            width={{ lg: '185px', base: 'fit-content' }}
            height={{ lg: '64px', base: '40px' }}
            display={{ lg: 'flex', base: 'none' }}
          >
            <LogoIcon width="100%" height="100%"/>
          </Box>
        </Link>
        <Text
          mt={{ lg: 8, base: 5 }}
          maxW={{ base: 'unset', lg: '580px' }}
          fontSize={{ lg: '20px', base: '16px' }}
          lineHeight={{ lg: '25px', base: '20px' }}
          letterSpacing={{ lg: '0.2px', base: '0.16px' }}
          fontWeight={ 250 }
          color="white"
        >
            Blockscout is a tool for inspecting and analyzing EVM based blockchains. Blockchain explorer for Ultron Networks.
        </Text>
        <Text
          mt={{ lg: '60px', base: 10 }}
          fontSize="14px"
          lineHeight="18px"
          letterSpacing="0.14px"
          fontWeight={ 250 }
          color="white"
        >
          Copyright © Ultron
        </Text>
      </Box>
      <Box>
        <Flex
          flexDir="column"
          gap={ 6 }
          minW={{ lg: '580px', base: '100%' }}
        >
          { BLOCKSCOUT_LINKS.map(link => <FooterLinkItem { ...link } key={ link.title }/>) }
        </Flex>
      </Box>
    </Flex>
  );
};

export default React.memo(Footer);
