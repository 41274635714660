import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const $shadow = cssVar('menu-shadow');

const baseStyleList = defineStyle({
  borderWidth: '0',
  bg: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(31px)',
  boxShadow: $shadow.reference,
});

const baseStyleItem = defineStyle({
  bg: 'transparent',
  _hover: {
    bg: 'rgba(255, 255, 255, 0.05)',
  },
});

const baseStyle = definePartsStyle({
  list: baseStyleList,
  item: baseStyleItem,
});

const Menu = defineMultiStyleConfig({
  baseStyle,
});

export default Menu;
