export const staticTokensList = [
  'token-1inch',
  'token-aave',
  'token-ada',
  'token-ape',
  'token-atom',
  'token-avax',
  'token-axs',
  'token-bnb',
  'token-btc',
  'token-busd',
  'token-cake',
  'token-crv',
  'token-dai',
  'token-doge',
  'token-dot',
  'token-eth',
  'token-flux',
  'token-ftm',
  'token-ghost',
  'token-link',
  'token-mana',
  'token-matic',
  'token-near',
  'token-nft',
  'token-pepe',
  'token-sand',
  'token-shib',
  'token-snx',
  'token-ultron',
  'token-ulx',
  'token-uni',
  'token-uusdc',
  'token-uusdt',
  'token-uxlp',
  'token-wbtc',
  'token-weth',
  'token-usdt',
];
