import { Box, chakra, Image } from '@chakra-ui/react';
import React from 'react';

import app from 'configs/app/app';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const cartBackground = app.baseUrl + '/static/footer_gradient.png';

const Container = ({ children, className }: Props) => {
  return (
    <Box className={ className } minWidth={{ base: '100vw', lg: 'fit-content' }}>
      { children }

      <Image
        src={ cartBackground }
        position="absolute"
        bottom={ 0 }
        right={ 0 }
        width="100%"
        height={{ lg: '1200px', base: '700px' }}
        zIndex="-1"
      />
    </Box>
  );
};

export default React.memo(chakra(Container));
