import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStylePopper = defineStyle({
  zIndex: 'popover',
});

const baseStyleContent = defineStyle({
  bg: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(31px)',

  width: 'xs',
  border: 'none',
  borderColor: 'inherit',
  borderRadius: 'md',
  boxShadow: '2xl',
  zIndex: 'inherit',
  _focusVisible: {
    outline: 0,
    boxShadow: '2xl',
  },
});

const baseStyleHeader = defineStyle({
  px: 3,
  py: 2,
  borderBottomWidth: '1px',
});

const baseStyleBody = defineStyle({
  px: 4,
  py: 4,
});

const baseStyleFooter = defineStyle({
  px: 3,
  py: 2,
  borderTopWidth: '1px',
});

const baseStyleCloseButton = defineStyle({
  position: 'absolute',
  borderRadius: 'md',
  top: 1,
  insetEnd: 2,
  padding: 2,
});

const baseStyle = definePartsStyle({
  popper: baseStylePopper,
  content: baseStyleContent,
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
  arrow: {},
  closeButton: baseStyleCloseButton,
});

const Popover = defineMultiStyleConfig({
  baseStyle,
});

export default Popover;
