import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
  cssVar,
} from '@chakra-ui/styled-system';
import { runIfFn } from '@chakra-ui/utils';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const $size = cssVar('checkbox-size');

const baseStyleControl = defineStyle({
  width: '28px',
  height: '28px',
  borderColor: 'white',
  _checked: {
    bg: 'linear-gradient(90deg, #1FFFA3 0%, #8C47F5 100%)',
    border: 'none',
    color: 'white',
    _hover: {
      bg: 'linear-gradient(90deg, #1FFFA3 0%, #8C47F5 100%)',
    },
  },
});

const sizes = {
  sm: definePartsStyle({
    control: { [$size.variable]: 'sizes.3' },
    label: { fontSize: 'sm' },
    icon: { fontSize: '3xs' },
  }),
  md: definePartsStyle({
    control: { [$size.variable]: 'sizes.4' },
    label: { fontSize: 'md' },
    icon: { fontSize: '2xs' },
  }),
  lg: definePartsStyle({
    control: { [$size.variable]: 'sizes.5' },
    label: { fontSize: 'md' },
    icon: { fontSize: '2xs' },
  }),
};

const baseStyleLabel = defineStyle({
  color: 'white',
  marginInlineStart: '16px',
  _disabled: {
    opacity: 0.2,
  },
});

const baseStyleIcon = defineStyle({
  width: '18px',
});

const baseStyle = definePartsStyle((props) => ({
  label: baseStyleLabel,
  icon: baseStyleIcon,
  control: runIfFn(baseStyleControl, props),
}));

const Checkbox = defineMultiStyleConfig({
  baseStyle,
  sizes,
});

export default Checkbox;
