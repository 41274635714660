import { chakra, Text } from '@chakra-ui/react';
import React from 'react';

import type { GasPriceInfo } from 'types/api/stats';
import type { GasUnit } from 'types/client/gasTracker';

import config from 'configs/app';

import formatGasValue from './formatGasValue';

const feature = config.features.gasTracker;

const UNITS_TO_API_FIELD_MAP: Record<GasUnit, 'price' | 'fiat_price'> = {
  gwei: 'price',
  usd: 'fiat_price',
};

interface Props {
  data: GasPriceInfo | null;
  className?: string;
  unitMode?: 'primary' | 'secondary';
  prefix?: string;
}

const GasPrice = ({ data, prefix, className, unitMode = 'primary' }: Props) => {
  if (!data || !feature.isEnabled) {
    return null;
  }

  switch (unitMode) {
    case 'secondary': {
      const primaryUnits = feature.units[0];
      const secondaryUnits = feature.units[1];

      if (!secondaryUnits) {
        return null;
      }

      const primaryUnitsValue = data[UNITS_TO_API_FIELD_MAP[primaryUnits]];
      if (!primaryUnitsValue) {
        // in this case we display values in secondary untis in primary mode as fallback
        return null;
      }

      const secondaryUnitsValue = data[UNITS_TO_API_FIELD_MAP[secondaryUnits]];
      if (!secondaryUnitsValue) {
        return null;
      }

      const formattedValue = formatGasValue(data, secondaryUnits);
      return (
        <Text
          bgClip="text"
          bgGradient="linear-gradient(90deg, #1FFFA3 0%, #8C47F5 100%)"
          className={ className }
        >{ prefix }{ formattedValue }</Text>
      );
    }
    case 'primary': {
      const primaryUnits = feature.units[0];
      const secondaryUnits = feature.units[1];

      if (!primaryUnits) {
        // this should never happen since feature will be disabled if there are no units at all
        return null;
      }

      const value = data[UNITS_TO_API_FIELD_MAP[primaryUnits]];
      if (!value) {
        // in primary mode we want to fallback to secondary units if value in primary units are not available
        // unless there are no secondary units
        const valueInSecondaryUnits = data[UNITS_TO_API_FIELD_MAP[secondaryUnits]];

        if (!secondaryUnits || !valueInSecondaryUnits) {
          // in primary mode we always want to show something
          // this will return "N/A <units>"
          return (
            <Text
              bgClip="text"
              bgGradient="linear-gradient(90deg, #1FFFA3 0%, #8C47F5 100%)"
              className={ className }
            >{ formatGasValue(data, primaryUnits) }</Text>
          );
        } else {
          return (
            <Text
              bgClip="text"
              bgGradient="linear-gradient(90deg, #1FFFA3 0%, #8C47F5 100%)" className={ className }
            >{ prefix }{ formatGasValue(data, secondaryUnits) }</Text>
          );
        }
      }

      return (
        <Text
          bgClip="text"
          bgGradient="linear-gradient(90deg, #1FFFA3 0%, #8C47F5 100%)"
          className={ className }
        >{ prefix }{ formatGasValue(data, primaryUnits) }</Text>
      );
    }
  }
};

export default chakra(GasPrice);
