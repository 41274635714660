import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';

import useColors from './useColors';

type Props = {
  isExpanded?: boolean;
  isCollapsed?: boolean;
  isActive?: boolean;
  px?: string | number;
}

export default function useNavLinkProps({ isExpanded, isCollapsed, isActive }: Props) {
  const colors = useColors();

  return {
    itemProps: {
      py: 2.5,
      display: 'flex',
      color: isActive ? colors.text.active : colors.text.default,
      bgColor: isActive ? 'rgba(255, 255, 255, 0.10)' : colors.bg.default,
      _hover: { color: isActive ? colors.text.active : colors.text.hover },
      _before: {
        display: isActive ? 'flex' : 'none',
        position: 'absolute',
        content: '""',
        left: 0,
        top: 'calc(50% - 12px)',
        width: '2px',
        height: '24px',
        background: 'linear-gradient(180deg, #1FFFA3 0%, #8C47F5 104.17%)',
      },
      ...getDefaultTransitionProps({ transitionProperty: 'width, padding' }),
    },
    textProps: {
      variant: 'inherit',
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 400,
      opacity: { base: '1', lg: isExpanded ? '1' : '0', xl: isCollapsed ? '0' : '1' },
      transitionProperty: 'opacity',
      transitionDuration: 'normal',
      transitionTimingFunction: 'ease',
      letterSpacing: '0.14px',
    },
  };
}
